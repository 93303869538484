.ic-inline {
  line-height: initial;

  svg {
    display: inline-block;
  }
}

ic-icon:not(.ic-inline)>svg,
.iconify:not(.ic-inline)>svg {
  margin: 0 auto;
  vertical-align: middle;
}

.vex-scrollbar {
  min-height: 0;
}

.vex-scrollblock {
  position: fixed;
  width: 100%;
}

.vex-style-dark {
  .toast-container {
    .ngx-toastr {
      background-color: #dce3f7 !important;
    }
  }
}

.toast-container {
  .ngx-toastr {
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: 0 0 5px #999999 !important;
    border-radius: 4px !important;
    padding: 10px 10px 10px 50px !important;

    &:hover {
      box-shadow: 0 0 5px #999999 !important;
    }
  }

  .toast-title {
    font-family: Roboto,
      -apple-system,
      "system-ui",
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple ColorDef Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto ColorDef Emoji";
    font-size: 14px;
    font-weight: 700;
  }

  .toast-message {
    color: var(--vex-sidenav-item-color);
    padding-right: 20px;
    font-family: Roboto,
      -apple-system,
      "system-ui",
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple ColorDef Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto ColorDef Emoji";
  }

  .toast-close-button {
    position: absolute;
    transform: translate(0px, -50%);
    top: 50%;
    right: 12px;
    font-weight: normal;
    text-shadow: none;
    float: right;
    font-size: 25px;
    color: var(--vex-sidenav-item-color);
  }

  .toast-success {
    border-left: 4px solid #4dd75b;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="%234dd75b" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"%2F%3E%3C%2Fsvg%3E');
  }

  .toast-info {
    border-left: 4px solid #2489f7;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="%232489f7" d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"%2F%3E%3C%2Fsvg%3E');
  }

  .toast-warning {
    border-left: 4px solid #febd27;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="%23febd27" d="M13 13h-2V7h2m0 10h-2v-2h2M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"%2F%3E%3C%2Fsvg%3E');
  }

  .toast-error {
    border-left: 4px solid #d83636;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="%23d83636" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"%2F%3E%3C%2Fsvg%3E');
  }
}