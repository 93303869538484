.mdc-notched-outline__notch {
    border-right: none;
}

:root {
    .mdc-text-field--outlined {
        --mdc-outlined-text-field-container-shape: theme('borderRadius.DEFAULT');
        padding: 0 10.5px !important;
    }

    .mat-mdc-input-element {
        background-color: var(--background-card) !important;
    }
}