@import "../mixins";

// @formatter:off
body {
  // Generic
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-4: 0.25rem;

  @screen lg {
    --padding-gutter: 1.5rem;
  }

  // Sidenav
  --vex-sidenav-background: #1a202e;
  --background-app-bar: #ebebee;
  --toolbar-icon-color: #5c77ff;
  --vex-sidenav-color: white;
  --background-card: #ffffff;
  --vex-sidenav-toolbar-background: theme("colors.gray.900");
  --vex-sidenav-section-divider-color: theme("colors.white / 0.12");
  --toolbar-background: var(--background-card);
  --background-base: rgb(245, 245, 248);

  // Sidenav Item
  --vex-sidenav-item-background-hover:#141924;
  --vex-sidenav-item-background-active: #141924;
  --vex-sidenav-item-color: #A1A2B6;
  --vex-sidenav-item-color-hover: white;
  --vex-sidenav-item-color-active: white;
  --vex-sidenav-item-icon-color: #494b74;
  --vex-sidenav-item-icon-color-hover: theme("colors.primary.500");
  --vex-sidenav-item-icon-color-active: theme("colors.primary.500");
  --vex-sidenav-item-dropdown-background: var(--vex-sidenav-item-background-active);
  --vex-sidenav-item-dropdown-background-hover: theme("colors.gray.950 / 80%");
  --vex-sidenav-item-dropdown-color-hover: var(--vex-sidenav-item-color-hover);
  --vex-sidenav-item-dropdown-background-active: var(--vex-sidenav-item-dropdown-background-hover);
  --vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color-active);
  --vex-sidenav-item-ripple-color: rgb(255 255 255, 0.1);
  --vex-sidenav-subheading-color: theme("colors.primary.300 / 80%");

  // Toolbar
  --vex-toolbar-background: theme("backgroundColor.foreground");
  --vex-toolbar-icon-color: rgb(var(--vex-color-primary-600));

  // Secondary Toolbar
  --vex-secondary-toolbar-background: var(--vex-toolbar-background);

  // Navigation
  --vex-navigation-color: theme("textColor.default");
  --vex-navigation-background: var(--vex-background-card);
  --navigation-height: 64px;

  --foreground-divider: rgba(82, 63, 105, .06);
  --mat-header-cell-color: #0000008a;
  --text-color: rgba(0, 0, 0, .87);
  --font-title: 500 18px / 26px var(--vex-font);
  --text-color-light: white;

  --elevation-default: var(--elevation-z6);
  --elevation-z0: none;
  --elevation-z1: #{vex-elevation(1)};
  --elevation-z2: #{vex-elevation(2)};
  --elevation-z3: #{vex-elevation(3)};
  --elevation-z4: #{vex-elevation(4)};
  --elevation-z5: #{vex-elevation(5)};
  --elevation-z6: #{vex-elevation(6)};
  --elevation-z7: #{vex-elevation(7)};
  --elevation-z8: #{vex-elevation(8)};
  --elevation-z9: #{vex-elevation(9)};
  --elevation-z10: #{vex-elevation(10)};
  --elevation-z11: #{vex-elevation(11)};
  --elevation-z12: #{vex-elevation(12)};
  --elevation-z13: #{vex-elevation(13)};
  --elevation-z14: #{vex-elevation(14)};
  --elevation-z15: #{vex-elevation(15)};
  --elevation-z16: #{vex-elevation(16)};
  --elevation-z17: #{vex-elevation(17)};
  --elevation-z18: #{vex-elevation(18)};
  --elevation-z19: #{vex-elevation(19)};
  --elevation-z20: #{vex-elevation(20)};

  --text-color: rgba(0, 0, 0, .87);
  --text-color-light: white;
  --text-secondary: rgba(0, 0, 0, .54);
  --text-secondary-light: rgba(255, 255, 255, .7)white;
  --text-hint: rgba(0, 0, 0, .38);
  --text-hint-light: rgba(255, 255, 255, .5);
  --background-hover: rgba(0, 0, 0, .04);

  // Page Layouts
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;

  // Misc
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 4px;

  // Toolbar
  --toolbar-height: 64px;

  // Secondary Toolbar
  --secondary-toolbar-height: 54px;

  --datepicker-toggle-icon-color: #0000008a;

  --select-option-text-color: rgba(0, 0, 0);

  --hierarchy-search-background: #ffff00;
}

// @formatter:on