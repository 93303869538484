$net-state-colors: (
  'gray': #9e9e9e,
  'red': #f44336,
  'green': #4caf50,
  'green-dark': #346d34,
  'blue': #5c77ff,
  'yellow': #ffc107
);

$net-label-colors: 'red' #ff363d #ffeeee,
  'soft-red' #ff373b #ffcfcd,
  'light-red' #fe8743 #fe87438a,
  'green' #36c538 #e5fde5,
  'gray' #737373 #e6e4e5,
  'light-blue' #00cdc8 #d6f5f09c,
  'yellow' #e9b511 #ffefb6a3,
  'blue' #149ee7 #d3f0fe9e,
  'brown' #95714d #f9efe5,
  'purple' #823afe #ddcbfd;

$net-label-dark-colors: 'red' #f93b31 #6c1f25,
  'soft-red' #f9393a #562f30,
  'light-red' #f55802 #563224,
  'green' #21e322 #15391f,
  'gray' #808080 #e6e4e5,
  'light-blue' #3ee8e5 #2f4341,
  'yellow' #e3b30e #3b3211,
  'blue' #08a7fb #2b414c,
  'brown' #bb8c65 #494037,
  'purple' #c073f9 #3f1f74;

$net-lead-colors: (
  'assigned-to-sales': #567e97,
  'assigned-to-tm': #4c5877,
  'draft': #316250,
  'future-lead': #b2b974,
  'no-potential': #aca9ba,
  'rejected-by-sales': #8b889a
);

$net-activity-colors: (
  'completed': #5474b0,
  'planned': #59a491,
  'cancelled': #9e9ab0,
);

$net-assignment-status-colors: (
  'cancelled': #9e9ab0,
  'rejected': #554d94,
  'assigned': #7eb7c6,
  'completed': #5474b0,
);

$net-assignment-priority-colors: (
  'urgent': #bc5050,
  'high': #de8668,
  'medium': #ddbf55,
  'low': #b6c676,
);

$net-offer-status-colors: (
  'preparing': #599abc,
  'sent': #59bcab,
  'approved': #6ac07a,
  'denied': #515e6b,
);

$net-offer-option-colors: (
  'nth': #4e0167,
  'single': #ab88f4,
);

$net-opportunity-approval-status-colors: (
  'waiting-for-approval': #e8c36a,
  'approved': #59ab78,
  'rejected': #d55b58,
  'cancelled': #b6c3d1,
);