.mat-mdc-icon-button .mat-mdc-button-wrapper>*,
.mat-mdc-menu-item .mat-mdc-icon,
.mat-mdc-button .mat-mdc-icon {
    vertical-align: middle !important;
}

.mat-mdc-icon.net-iconify-icon,
.mat-mdc-icon-button .mat-mdc-icon.net-iconify-icon {
    font-size: var(--default-icon-size);
    height: unset;
    width: unset;
}

.mat-mdc-form-field-suffix,
.mat-mdc-form-field-prefix {

    .mat-mdc-icon-button .mdc-icon-button {
        font-size: inherit;
        width: 1.5em;
        height: 1.5em;
    }

    .mat-mdc-datepicker-toggle-default-icon {
        width: 1em;
        margin: 0px 4px 0px 4px;
    }
}

.mat-mdc-form-field-icon-suffix {
    position: relative;
    top: 5px;

    .mat-datepicker-toggle {
        flex: 1 1 100%;
        max-width: 10%;
        color: var(--datepicker-toggle-icon-color) !important;

        .mat-mdc-icon-button,
        .mdc-icon-button {
            font-size: inherit;
            width: 1.5em;
            height: 1.5em;

            net-iconify-icon {
                position: absolute;
                bottom: 4px;
                right: 4px;
            }
        }

        .mat-datepicker-toggle-default-icon {

            width: 13.6px;
            height: 13.6px;
        }


    }

    .mat-mdc-icon-button,
    .mdc-icon-button {
        font-size: inherit;
        width: 1.5em;
        height: 1.5em;

        net-iconify-icon,
        svg {
            position: absolute;
            bottom: 4px;
            right: 4px;
        }
    }
}

.mat-mdc-form-field {

    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align {
        height: 0 !important;
        padding-bottom: 1.25em !important;
    }
}

.mat-mdc-primary.mat-mdc-form-field {

    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix {
        transition: var(--trans-ease-out) !important;
    }

    &.mat-mdc-focused .mat-mdc-form-field-prefix,
    &.mat-mdc-focused .mat-mdc-form-field-suffix {
        @apply text-primary-500;
    }
}

.mat-mdc-form-field.mat-mdc-form-field-invalid {

    &.mat-mdc-focused .mat-mdc-form-field-prefix,
    &.mat-mdc-focused .mat-mdc-form-field-suffix {
        @apply text-red-500;
    }
}



.mat-mdc-menu-item net-iconify-icon {
    margin-inline-end: var(--padding-16);
    vertical-align: middle;

    >svg {
        vertical-align: middle;
    }
}

.mat-mdc-select-panel {
    background-color: var(--background-card) !important;
    @apply text-base;
}

textarea.mat-mdc-input-element {
    @apply leading-normal;
}

.vex-flex-form-field {
    .mat-mdc-form-field-infix {
        width: 50px;
    }
}

.vex-dense-form-field {
    margin-bottom: -1.34375em;

    &.mat-mdc-form-field-appearance-outline {
        .mat-mdc-form-field-infix {
            padding-top: 4px;
        }
    }
}

.vex-tabs {
    .mat-mdc-tab-label.mat-mdc-tab-label-active {
        opacity: 1;
    }

    .mat-mdc-tab-link.mat-mdc-tab-label-active {
        opacity: 1;
    }
}

.vex-tabs-dense {
    .mat-mdc-tab-label {
        min-width: 0;
    }
}

.mat-accent {
    --mdc-outlined-button-label-text-color: #ff4081 !important;
    --mat-outlined-button-state-layer-color: #ff4081 !important;
    --mat-outlined-button-ripple-color: #ff4081 !important;
}

.mat-mdc-form-field {
    --mdc-filled-text-field-container-color: transparent !important;
    --mat-form-field-filled-label-display: unset !important;

    // margin-bottom: 4px !important;
}

.mdc-floating-label {
    pointer-events: none !important;
    @apply z-1;
}

.mdc-floating-label--float-above {
    --mat-form-field-container-height: 40px !important;
    width: auto !important;
}

.mat-mdc-floating-label {
    --mat-form-field-container-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.mat-form-field-disabled {
    --mdc-filled-text-field-disabled-container-color: transparent !important;
}

.mat-mdc-select-panel {
    min-width: calc(100% + 64px) !important;
}

.mdc-text-field--filled {

    /*ON HOVER CHANGE BACKGROUND COLOR*/
    .mat-mdc-form-field-focus-overlay {
        background-color: var(--mdc-filled-text-field-container-color) !important;
    }

    .mat-mdc-floating-label {
        display: unset !important;
        border-bottom: .24375em solid transparent !important;
    }

    .mat-mdc-form-field-infix {
        align-items: center !important;
        display: flex !important;
        padding-bottom: 0 !important;
        min-height: 38px !important;
    }

    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.mdc-checkbox {
    display: inline-block;
    height: 16px !important;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    flex-shrink: 0;

    margin: 0 !important;
    padding: 0 !important;

}

.mat-pseudo-checkbox-checked {
    background: theme("colors.primary.500") !important;
    width: 16px;
    height: 16px;
}

.mdc-switch:disabled.mdc-switch--selected {
    --mdc-switch-disabled-track-opacity: .38;
    --mdc-form-field-label-text-tracking: #5c77ff;

    .mdc-switch__track {

        &::after {
            //background: #5c77ff8a; 
            --mdc-switch-disabled-selected-track-color: #5c77ff8a;
        }
    }

    .mdc-switch__shadow {
        background-color: var(--mdc-form-field-label-text-tracking) !important;
        opacity: .5 !important;
    }
}


.dark .mdc-switch:enabled.mdc-switch--unselected {
    --mdc-switch-unselected-track-color: #ffffff80;
    --mdc-switch-unselected-hover-track-color: #ffffff80;
    --mdc-switch-unselected-focus-track-color: #ffffff80;
    --mdc-switch-unselected-pressed-track-color: #ffffff80;

    --mdc-switch-unselected-handle-color: #fafafa;
    --mdc-switch-unselected-hover-handle-color: #fafafa;
    --mdc-switch-unselected-focus-handle-color: #fafafa;
    --mdc-switch-unselected-pressed-handle-color: #fafafa;

    .mdc-switch__track {
        &::before {}
    }

    .mdc-switch__shadow {
        background-color: var(--mdc-form-field-label-text-tracking) !important;
        opacity: .5 !important;
    }
}

.mdc-switch:enabled.mdc-switch--unselected {
    --mdc-switch-unselected-track-color: #00000061;
    --mdc-switch-unselected-hover-track-color: #00000061;
    --mdc-switch-unselected-focus-track-color: #00000061;
    --mdc-switch-unselected-pressed-track-color: #00000061;

    --mdc-switch-unselected-handle-color: #fafafa;
    --mdc-switch-unselected-hover-handle-color: #fafafa;
    --mdc-switch-unselected-focus-handle-color: #fafafa;
    --mdc-switch-unselected-pressed-handle-color: #fafafa;

    .mdc-switch__track {
        &::before {}
    }

    .mdc-switch__shadow {
        background-color: var(--mdc-form-field-label-text-tracking) !important;
        opacity: .5 !important;
    }
}

.mat-mdc-checkbox-touch-target {
    height: 16px !important;
    width: 16px !important;
}

.mdc-checkbox .mdc-checkbox__native-control {
    height: 16px !important;
    width: 16px !important;
}

.mdc-label {
    font-size: 14px !important;
}

.mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
    width: 16px !important;
    height: 16px !important;
}

.mdc-text-field {
    padding: 0 !important;
}

.mat-mdc-form-field {
    margin-bottom: 4px;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0 1em;
}

.mat-mdc-menu-item net-iconify-icon {
    margin-inline-end: var(--padding-16);
    vertical-align: middle;

    >svg {
        vertical-align: middle;
    }
}

.mat-mdc-icon-button .mat-mdc-button-wrapper>*,
.mat-mdc-menu-item .net-iconify-icon,
.mat-mdc-button .net-iconify-icon {
    vertical-align: middle !important;
}

.mat-mdc-table .mat-mdc-cell,
.mat-mdc-table .mat-mdc-header-cell {
    box-sizing: content-box;
    padding-left: var(--padding-12);
    padding-right: var(--padding-12);
    white-space: nowrap;
}

.mat-mdc-table .mat-mdc-cell,
.mat-mdc-table .mat-mdc-header-cell {
    &:first-of-type {
        padding-left: 24px;
    }
}


.mat-mdc-paginator-page-size-select {
    padding-top: 0 !important;
    width: 56px !important;
}

.mat-mdc-paginator-range-label {
    font-family: var(--vex-font);
    font-size: 12px;
}

.mat-mdc-table {

    mat-row,
    mat-header-row,
    mat-footer-row,
    th.mat-mdc-header-cell,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell,
    th.mat-mdc-footer-cell {
        border-bottom-color: var(--foreground-divider) !important;
    }

}

.uppercase>*>.mat-mdc-sort-header-button {
    text-transform: uppercase;
}

.mat-mdc-header-row {
    .mat-mdc-header-cell {
        font-size: 12px !important;
        font-weight: 500 !important;
        color: var(--mat-header-cell-color) !important;
        border-bottom-color: var(--foreground-divider);

        &:last-child {
            border-bottom-color: var(--foreground-divider);
        }
    }

    .mat-mdc-sort-header-content {
        font-size: 12px !important;
        font-weight: 500 !important;
        color: var(--mat-header-cell-color) !important;
        border-bottom-color: var(--foreground-divider);

        &:last-child {
            border-bottom-color: var(--foreground-divider);
        }
    }

    font-size: 12px !important;
    font-weight: 500 !important;
    color: var(--mat-header-cell-color) !important;
    border-bottom-color: var(--foreground-divider);
}

.mat-mdc-row {
    height: 48px !important;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: 1px solid var(--foreground-divider) !important;
}

.mdc-tooltip__surface {
    text-align: left !important;
    font-size: 10px !important;
    background-color: rgba(97, 97, 97, 0.9) !important;
}