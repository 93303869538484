@use '@angular/material' as mat;
@import './themes';

/**
 * Typography
 */
:root {
  .display-4 {
    @include mat.typography-level($typography-config, headline-1);
  }

  .display-3 {
    @include mat.typography-level($typography-config, headline-2);
  }

  .display-2 {
    @include mat.typography-level($typography-config, headline-3);
  }

  .display-1,
  h1 {
    @include mat.typography-level($typography-config, headline-4);
  }

  .headline,
  h2 {
    @include mat.typography-level($typography-config, headline-5);
  }

  .title,
  h3 {
    @include mat.typography-level($typography-config, headline-6);
  }

  .subheading-2,
  h4 {
    @include mat.typography-level($typography-config, subtitle-1);
  }

  .subheading-1,
  h5 {
    @include mat.typography-level($typography-config, body-1);
  }

  .body-2,
  h6 {
    @include mat.typography-level($typography-config, subtitle-2);
  }

  .body-1,
  p {
    @include mat.typography-level($typography-config, body-2);
  }

  .caption {
    @include mat.typography-level($typography-config, caption);
  }

  .button {
    @include mat.typography-level($typography-config, button);
  }

  .text-xs {
    letter-spacing: 0;
  }

  .text-sm {
    letter-spacing: -0.009em;
  }

  .text-base {
    letter-spacing: -0.011em;
  }

  .text-lg {
    letter-spacing: -0.014em;
  }

  .text-2xl {
    letter-spacing: -0.019em;
  }

  .text-3xl {
    letter-spacing: -0.021em;
  }
}