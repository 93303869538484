:root {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    display: block !important;
    padding: 24px !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    overflow: auto;
    outline: 0 !important;
    width: 100% !important;
    height: 100% !important;
    min-height: inherit !important;
    max-height: inherit !important;
    background-color: var(--background-card);
  }

  .mat-mdc-dialog-content {
    margin: 0px -24px;
    // padding: 0 24px !important;
    padding: 0px;
    color: var(--text-color) !important;
  }

  .mat-mdc-dialog-title {
    font: 400 24px / 32px var(--vex-font) !important;
    letter-spacing: normal !important;
    padding: 0 !important;
    // @apply pt-3;
    margin: 0 0 20px !important;
  }

  /** Reset default height placeholder */
  .mdc-dialog__title::before {
    content: none;
    height: auto;
  }
}