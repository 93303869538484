// @formatter:off
.dark {
  // Sidenav
  --vex-sidenav-background: #1a202e;
  --background-card: #1a202e;
  --background-app-bar: #11151e;
  --toolbar-icon-color: #5c77ff;
  --background-base: #232b3e;
  --vex-sidenav-color: theme("colors.white");
  --toolbar-background: var(--background-card);

  --vex-sidenav-toolbar-background: theme("colors.gray.900");
  --vex-sidenav-section-divider-color: theme("colors.white / 0.12");

  // Sidenav Item
  --vex-sidenav-item-background-hover: #141924;
  --vex-sidenav-item-background-active: #141924;
  --vex-sidenav-item-color: #A1A2B6;
  --vex-sidenav-item-color-hover: white;
  --vex-sidenav-item-color-active: white;
  --vex-sidenav-item-icon-color: #494b74;
  --vex-sidenav-item-icon-color-hover: #5c77ff;
  --vex-sidenav-item-icon-color-active: #5c77ff;
  --vex-sidenav-item-dropdown-background: var(--vex-sidenav-item-background-active);
  --vex-sidenav-item-dropdown-background-hover: theme("colors.gray.950 / 80%");
  --vex-sidenav-item-dropdown-color-hover: var(--vex-sidenav-item-color-hover);
  --vex-sidenav-item-dropdown-background-active: var(--vex-sidenav-item-dropdown-background-hover);
  --vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color-active);
  --vex-sidenav-item-ripple-color: rgb(255 255 255, 0.1);
  --vex-sidenav-subheading-color: theme("colors.gray.500");
  --vex-background-background: var(--background-base);

  // Toolbar
  // --vex-toolbar-background: var(--vex-background-background);
  --vex-toolbar-icon-color: rgb(var(--vex-color-primary-600));

  // Secondary Toolbar
  --vex-secondary-toolbar-background: var(--vex-background-background);

  // Navigation
  --vex-navigation-color: theme("textColor.default");
  --vex-navigation-background: theme("colors.gray.900");
  --foreground-divider: #ffffff1f;
  --mat-header-cell-color: #ffffffb3;
  --text-color: white;
  --font-title: 500 18px / 26px var(--vex-font);
  --text-color-light: rgba(0, 0, 0, .87);
  --chat-shadow: rgb(50, 64, 96, 0.73);

  --text-hint: rgba(255, 255, 255, .5);
  --text-hint-light: rgba(0, 0, 0, .38);
  --background-hover: rgba(255, 255, 255, .04);
  --text-secondary: rgba(255, 255, 255, .7);
  --text-secondary-light: rgba(0, 0, 0, .54);
  --text-color-light: rgba(0, 0, 0, .87);
  --secondary-toolbar-background: var(--background-card);

  --datepicker-toggle-icon-color: #ffffff;

  --select-option-text-color: #fff;

  --hierarchy-search-background: #ff0000;
}

// @formatter:on