@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
// Vex Core
@import "./@vex/styles/core.scss";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
html {
  // --vex-color-primary: blue;
  // --vex-color-primary-contrast: white;

  // --vex-color-accent: yellow;
  // --vex-color-accent-contrast: black;

  // --vex-color-warn: yellow;
  // --vex-color-warn-contrast: black;
}

*:focus {
  outline: none;
}

[hidden] {
  display: none !important;
}


body {
  background: #3d4452;
  font-family: var(--vex-font);
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--vex-font) !important;
}

.net-label {
  display: inline-block;
  line-height: 1 !important;
  text-align: center;
  padding: 7px 10px;

  @apply rounded font-medium text-xs cursor-default;

  @each $name,
  $color in $net-state-colors {
    &.label-color-#{$name} {
      color: $color;
      background-color: rgba($color, .09);
    }
  }
}

@each $name, $background in $net-activity-colors {
  .activity-status-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

@keyframes shake {

  from,
  to {
    transform: translateX(0);
  }

  30%,
  60%,
  90% {
    transform: translateX(-10px);
  }

  15%,
  45%,
  75% {
    transform: translateX(10px);
  }
}

.mat-mdc-form-field {

  &.hide-underline {
    .mdc-line-ripple {
      display: none;
    }
  }
}

.mat-mdc-form-field.mat-form-field-invalid {

  .mat-mdc-text-field-wrapper {
    animation-name: shake;
    animation-duration: .5s;
  }
}

.mdc-button__label {
  width: max-content;
}

.vex-style-dark {
  .net-label-status {
    display: inline-block;
    line-height: 1;
    padding: 7px 10px;
    min-width: 184px;
    text-align: center;

    @apply rounded font-medium text-xs cursor-default;

    @each $name,
    $text,
    $background in $net-label-dark-colors {
      &.label-status-color-#{$name} {
        color: $text;
        background-color: $background;
      }
    }
  }

  .ck {
    .ck-icon {
      color: white !important;
    }

    .ck-heading-dropdown .ck-button__label {
      color: white !important;
    }

    .ck-dropdown__panel .ck-button__label {
      color: inherit !important;
    }

    .ck-color-table .ck-icon {
      color: inherit !important;
    }

    .ck-dropdown__panel .ck-icon {
      color: inherit !important;
    }

    .ck-button {
      &.ck-on {
        color: white !important;
        background-color: #5c77ff !important;
      }

      &:not(.ck-list__item .ck-button, .ck-color-table .ck-button) {
        &:hover {
          color: white !important;
          background-color: #5c77ff !important;
        }
      }
    }

    .ck-content {
      background-color: #1a202e !important;
    }

    .ck-color-table {
      background-color: #1a202e !important;
    }

    .ck-toolbar__items {
      background-color: #1a202e !important;

      .ck-button {
        color: white !important;
      }
    }

    .ck-list__item {
      background-color: #1a202e !important;
      color: white !important;

      .ck-button_with-text {
        color: white !important;

        &:hover {
          color: white !important;
          background-color: #5c77ff !important;
        }
      }
    }
  }
}

.mdc-text-field--filled:is(.mdc-text-field--disabled) .mdc-line-ripple::before {
  // outline-color: rgba(82, 63, 105, .42);
  background-color: rgba(0, 0, 0, 0);
  background-position: 0;
  background-image: linear-gradient(to right, rgba(82, 63, 105, .42) 0%, rgba(82, 63, 105, .42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
  height: 1px;
  border-bottom-width: 0;
}

.vex-style-dark .mdc-text-field--filled:is(.mdc-text-field--disabled) .mdc-line-ripple::before {
  background-position: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
  height: 1px;
  border-bottom-width: 0;
}


#net-splash-screen {
  .reload-warning {
    width: 400px;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    bottom: 40%;
  }
}

.mat-mdc-menu-panel.call-filter {
  max-width: none !important;
  max-height: 500px !important;
}

.call-filter {
  .mat-mdc-menu-content:not(:empty) {
    padding: 2px;
  }

  .table-filter-list {
    width: 500px;
    max-width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
  }
}

.simplebar-scrollbar:before {
  background-color: var(--vex-sidenav-item-icon-color);
  border-radius: 25px !important;
}

.table-filter {
  .mat-mdc-menu-content:not(:empty) {
    padding: 2px;
  }

  .table-filter-list {
    width: 500px;
    max-width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
  }

  .table-filter-list.cdk-drop-list-dragging {
    .table-filter-box {
      &:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

}

.table-filter-box {
  padding: 5px 5px 0px 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
  background-color: var(--background-card);

  .drag-icon {
    color: var(--text-color);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container {
    cursor: pointer;
  }

  .table-filter-checkbox {
    text-align: left;
    width: 100%;
    cursor: move !important;
  }
}

.mat-mdc-paginator-range-actions {
  margin-right: 1rem;
}

.mat-mdc-paginator-range-actions .mat-mdc-paginator-icon {
  display: inline-block;
}

.mat-mdc-header-cell {
  .mat-sort-header-container {
    display: inline-flex;
    margin-right: 25px;
  }

  .mat-header-filter {
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }

  &:hover,
  :focus,
  :active {
    .mat-header-filter {
      visibility: visible;
      opacity: 1;
      transition: opacity .4s ease-in-out;
    }
  }
}

.progress-button {
  background-color: #5c77ff !important;
  color: white !important;
  box-shadow: 5px 5px 5px 0px #c7c5e9;
  border-radius: 5px;
}

// .mat-mdc-text-field-wrapper {
//   background-color: var(--mat-table-background-color) !important;
//   border-bottom: none !important;
// }

// .mat-mdc-form-field-focus-overlay {
//   background-color: white !important;
// }

// .mdc-text-field {
//   padding: unset !important;
// }

// .mat-mdc-floating-label {
//   display: unset !important;
// }

// .mat-mdc-text-field-wrapper {
//   height: 42px !important;
// }

// .mat-mdc-form-field-infix {
//   padding: .4375em 0 !important;
// }

// .mat-mdc-select {
//   bottom: 5px !important;
//   position: absolute !important;
// }

// .mat-mdc-form-field-hint-wrapper {
//   padding: 0 !important;
// }

// .mat-mdc-form-field-hint {
//   font-size: 75% !important;
// }

// .mat-mdc-menu-item {
//   color: #0000008a !important;
// }

// .mat-date-range-input {
//   bottom: 5px !important;
//   position: absolute !important;
// }

// .mat-mdc-input-element {
//   bottom: 10px !important;
//   position: absolute !important;
// }

.mat-header-cell {
  .mat-sort-header-container {
    display: inline-flex;
    margin-right: 25px;
  }

  .mat-header-filter {
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }

  &:hover,
  :focus,
  :active {
    .mat-header-filter {
      visibility: visible;
      opacity: 1;
      transition: opacity .4s ease-in-out;
    }
  }
}

.font-medium {
  font-weight: 500 !important;
}

.mat-mdc-dialog-actions {
  padding: 0 !important;
  margin-bottom: 0 !important;
  min-height: auto !important;
}

.cdk-overlay-pane.card-dialog-pane {
  width: 740px !important;
  max-width: 100% !important;
  max-height: 100vh;

  .mat-mdc-dialog-container {
    overflow-y: hidden !important;
  }
}

@media(max-width: theme('screens.tablet')) {
  .cdk-overlay-pane.cdk-overlay-full {
    max-width: 95vw !important;
    width: 95vw;
  }
}


@media(min-width: theme('screens.md')) {
  .cdk-overlay-pane.form-dialog-pane {
    min-width: 880px !important;
  }
}

// Material Select Open Dropdown Position Bottom
.cdk-overlay-pane {

  // transform: none !important;
  .mat-mdc-select-panel {
    margin: 24px 0px;
  }
}

.cdk-overlay-pane.card-dialog-pane {
  width: 740px !important;
  max-width: 100% !important;
  max-height: 100vh;

  .mat-mdc-dialog-container {
    overflow-y: hidden;
  }
}

::ng-deep mat-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 24px !important;
  }
}

.listview-search-bar {
  @apply bg-card rounded-full border px-4 flex flex-row justify-start items-center md:min-w-[400px] md:max-w-[400px] max-sm:hidden flex-auto
}

.vex-style-default,
.vex-style-light {
  .net-label-status {
    display: inline-block;
    line-height: 1;
    padding: 7px 10px;
    min-width: 184px;
    text-align: center;

    @apply rounded font-medium text-xs cursor-default;

    @each $name,
    $text,
    $background in $net-label-colors {
      &.label-status-color-#{$name} {
        color: $text;
        background-color: $background;
      }
    }
  }

  .assignment-detail {
    .mat-input-element:disabled {
      color: rgb(0 0 0 / 60%) !important;
    }

    .mat-select-disabled .mat-select-value {
      color: rgb(0 0 0 / 60%) !important;
    }
  }
}

.vex-style-dark {
  .net-label-status {
    display: inline-block;
    line-height: 1;
    padding: 7px 10px;
    min-width: 184px;
    text-align: center;

    @apply rounded font-medium text-xs cursor-default;

    @each $name,
    $text,
    $background in $net-label-dark-colors {
      &.label-status-color-#{$name} {
        color: $text;
        background-color: $background;
      }
    }
  }

  .dropdown-icon {
    color: #ffffff8a !important;
  }
}

.mat-option-button,
.mat-option-button-group {
  padding: 0 16px;
  @apply mb-2;

  &>.mat-pseudo-checkbox {
    display: none !important;
  }

  .mdc-list-item__primary-text {
    display: flex;
  }

  .mat-mdc-raised-button {
    font-size: 0.7rem;
    font-weight: 400;
    margin-right: 7px;
    min-width: 63px;
    line-height: 20px;
    height: 20px;
    border-radius: 4px;

    &:last-child {
      margin-right: 0;
    }


    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
    --tw-bg-opacity: 1;
    background-color: rgb(158 158 158 / var(--tw-bg-opacity)) !important;
  }

}

.hide-input-number-arrow {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.alert {
  @apply bg-primary-50;
  border-left: 3px solid theme('colors.primary.500');
  color: rgba(0, 0, 0, .87);
  font-style: normal;
  padding: 0.5em 1.5em;

  >* {
    margin: 0;
  }

  &.alert-icon {
    position: relative;
    padding-right: 0.8em;
    padding-left: 2.8em;

    net-iconify-icon {
      position: absolute;
      top: 50%;
      left: 0.35em;
      transform: translate(-10%, -50%);
    }
  }

  &.alert-warning {
    @apply bg-orange-50;
    border-left: 3px solid theme('colors.orange.500');

    net-iconify-icon {
      color: theme('colors.orange.500');
    }
  }

  &.alert-error {
    @apply bg-red-50;
    border-left: 3px solid theme('colors.red.500');

    net-iconify-icon {
      color: theme('colors.orange.500');
    }
  }

  &.alert-primary {
    @apply bg-primary-50;
    border-left: 3px solid theme('colors.primary.600');
    color: theme('colors.primary.600');

    net-iconify-icon {
      color: theme('colors.primary.500');
    }
  }
}

.mat-elevation-z4 {
  box-shadow: var(--elevation-z4) !important;
}

.mat-mdc-menu-item-text {
  @apply flex flex-row;
}

.mdc-button__label {
  @apply flex flex-row items-center;
}

@each $name, $background in $net-lead-colors {
  .lead-status-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

@each $name, $background in $net-activity-colors {
  .activity-status-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

// @each $name, $background in $net-assignment-status-colors {
//   .assignment-status-#{$name} {
//     color: #fff;
//     background-color: $background;
//   }
// }

@each $name, $background in $net-assignment-priority-colors {
  .assignment-priority-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

@each $name, $background in $net-offer-status-colors {
  .offer-status-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

@each $name, $background in $net-offer-option-colors {
  .offer-option-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

@each $name, $background in $net-opportunity-approval-status-colors {
  .opportunity-approval-status-#{$name} {
    color: #fff;
    background-color: $background;
  }
}

.mat-slider-container {
  .mat-mdc-text-field-wrapper {
    overflow: visible;
  }

  .mat-mdc-slider .mdc-slider__tick-marks {
    visibility: hidden;
  }

  .mat-mdc-slider .mdc-slider__track--inactive {
    background-color: transparent;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
    outline: unset !important;
  }

}

.mat-mdc-outlined-button {

  @each $name,
  $color in $net-state-colors {

    &.net-color-#{$name},
    &.net-hover-color-#{$name}:not([disabled]):hover {
      color: $color;

      .mat-button-focus-overlay {
        background-color: $color;
      }
    }
  }
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: transparent !important;
}

.mdc-radio {
  padding: unset !important;
  margin-right: .25rem !important;
}

.mat-mdc-menu-item {
  net-iconify-icon {
    color: var(--text-secondary);
    display: flex;
    align-items: center;
  }
}

.vex-style-dark {
  .mat-mdc-menu-item {
    net-iconify-icon {
      color: var(--text-color);
    }
  }
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  bottom: -4px;
}

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 1.0625em;
}

.mat-mdc-paginator,
.mat-mdc-table {
  background: white;
}

.vex-style-dark {

  .mat-mdc-paginator,
  .mat-mdc-table {
    background: #1a202e !important;
  }
}

.mat-mdc-dialog-title {
  font: 500 18px / 26px var(--vex-font) !important;
  letter-spacing: normal !important;
  margin: 0 0 20px 0 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  text-wrap: unset !important;
}

.mat-mdc-option {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  line-height: 48px !important;
  height: 48px !important;
}

.mat-preview-cell {
  display: block;
  padding: 0.5rem 1rem;
  background-color: var(--background-card);
  outline: 1px solid var(--text-hint);
}

.custom-mat-field {
  font-size: 14px;
  color: var(--text-hint);

  &.has-error {
    label {
      color: theme('colors.red.500');
    }
  }
}

.grouped-zone {
  .mat-mdc-form-field {
    margin-bottom: unset !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    height: auto !important;
    min-height: unset !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }
}

.mdc-text-field--disabled,
.mdc-checkbox--disabled,
.mat-mdc-icon-button[disabled],
.mat-mdc-icon-button.mat-mdc-button-disabled {
  pointer-events: auto !important;
}

.vex-style-dark {

  .mat-mdc-select-disabled,
  .mat-mdc-input-element:disabled {
    color: #ffffff80 !important;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label--float-above {
    color: #ffffffb3 !important;
  }
}

.vex-style-default,
.vex-style-light {

  .mat-mdc-select-disabled,
  .mat-mdc-input-element:disabled {
    color: #00000061 !important;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label--float-above {
    color: #0000008a !important;
  }
}


.ck-editor-overflow {
  .mat-mdc-text-field-wrapper {
    @apply overflow-visible;
  }
}

.recurring-tooltip {
  white-space: pre-line;
}

net-survey-preview {
  display: flex;
  height: 100vh;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  content: " *" !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;

  .mat-mdc-form-field-hint {
    color: var(--mat-header-cell-color);
    @apply text-[10.5px];
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 0 !important;
  }

}