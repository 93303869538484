.vex-style-light {
  $sidenav-background: white;
  --background-app-bar: #ebebee;
  --toolbar-icon-color: #5c77ff;
  --background-card: #ffffff;
  --navigation-background: var(--background-card);
  --vex-sidenav-background: white;
  --background-base: rgb(245, 245, 248);

  // Sidenav
  --toolbar-background: var(--background-card);
  --vex-sidenav-color: var(--text-color);

  // Sidenav Item
  --vex-sidenav-item-background-active: #fafafa;
  --vex-sidenav-item-background-hover: #fafafa;
  --vex-sidenav-item-color: rgba(0, 0, 0, .87);
  --vex-sidenav-item-color-hover: rgba(0, 0, 0, .87);
  --vex-sidenav-item-color-active: rgba(0, 0, 0, .87);
  --vex-sidenav-item-dropdown-background: #{darken($sidenav-background, 2)};
  --vex-sidenav-item-dropdown-background-hover: #{darken($sidenav-background, 3)};
  --vex-sidenav-item-icon-color: #494B74;
  --vex-sidenav-item-icon-color-active: theme('colors.primary.500');
  --vex-sidenav-toolbar-background: #{$sidenav-background};

  --chat-shadow: rgba(234, 233, 241, 1);

  --foreground-divider: rgba(82, 63, 105, .06);
  --mat-header-cell-color: #0000008a;
  --font-title: 500 18px / 26px var(--vex-font);
  --text-color-light: white;

  --text-color: rgba(0, 0, 0, .87);
  --text-color-light: white;
  --text-secondary: rgba(0, 0, 0, .54);
  --text-secondary-light: rgba(255, 255, 255, .7);
  --text-hint: rgba(0, 0, 0, .38);
  --text-hint-light: rgba(255, 255, 255, .5);

  --datepicker-toggle-icon-color: #0000008a;
  --select-option-text-color: rgba(0, 0, 0);

  --hierarchy-search-background: #ffff00;
}