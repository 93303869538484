:root {
  .mat-drawer-container {
    color: inherit;
    background-color: var(--background-card) !important;
  }

  .mat-step {
    background-color: var(--background-card) !important;
  }

  .mat-drawer-side {
    @apply border-r-0;
  }

  .dark .mat-drawer-side {
    @apply border-r;
  }
}