:root {
  .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) div.mat-mdc-autocomplete-panel {
    // @apply mt-1 rounded-t;
  }

  .mat-mdc-autocomplete-panel {
    @apply rounded-b;

    .mat-mdc-option {
      // @apply mx-2;
    }
  }
}